
// Polyfills
import 'es6-promise/auto';  // needed for polyfill loading

import(
  /* webpackChunkName: "polyfills" */
  'respimage'
);
// import 'whatwg-fetch';
// import 'cq-prolyfill';

import 'utils/store';
import 'alpinejs';

// Components

import './patternlab/patterns/blocks/privacy/privacy';
import './patternlab/patterns/blocks/nav/nav';
import './patternlab/patterns/blocks/video/video';
import './patternlab/patterns/blocks/slider/slider';
import './patternlab/patterns/blocks/map/map';

// Interactive

import './patternlab/interactive/aos/aos.js';
import './patternlab/interactive/lightbox/lightbox.js';
import './patternlab/interactive/tooltip/tooltip.js';

// Statistics

import './js/ga.js';
