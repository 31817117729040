import ready from 'utils/ready';
import {$, $$} from 'utils/selector';

const tippyOptions = {
  animation: 'shift-away',
  theme: 'light',
  arrow: true,
  delay: [150, 150],
  maxWidth: '25rem',
  trigger: "mouseenter focus",
  allowHTML: true,
  interactive: true,
}

ready().then(() => {

  const selector = '[data-tooltip]';
  const elements = $$(selector);

  if (!elements || elements.length <= 0) return

  Promise.all([
    import(
      /* webpackChunkName: "tippy" */
      'tippy.js'
    ),
    import(
      /* webpackChunkName: "tippy" */
      'tippy.js/animations/shift-away.css'
    ),
    import(
      /* webpackChunkName: "tippy" */
      'tippy.js/dist/tippy.css'
    ),
    import(
      /* webpackChunkName: "tippy" */
      'tippy.js/themes/light.css'
    ),
    import(
      /* webpackChunkName: "tooltip" */
      './tooltip.css'
    )
  ]).then(modules => {
    var tippy = modules[0].default;

    for(let i = 0, l = elements.length; i < l; i++) {
      const tooltip   = elements[i];
      const htmlTitle = tooltip.getAttribute('data-tooltip');

      tooltip.setAttribute('data-tippy-content', htmlTitle);
      tippy(tooltip, tippyOptions);
    }
  });

});
