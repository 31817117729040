import ready from 'utils/ready';
import {$, $$} from 'utils/selector';

ready().then(() => {

  const className = '.js-swiper';
  const elements = $$(className);

  if (elements.length > 0) {

    // import (/* webpackChunkName: "slider" */'swiper/dist/css/swiper.css');
    import (/* webpackChunkName: "slider" */'./slider.css');
    import (/* webpackChunkName: "swiper" */'swiper').then(Swiper => {
      var Swiper = Swiper.default;

      elements.forEach(element => {

        let config = {
          autoHeight: true,
          dynamicBullets: true,

          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },

          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }
        };
        let data = element.dataset;
        for (let i in data) {
          let value = data[i];
          if (i == 'slidesperview') {
            config['slidesPerView'] = parseInt(value);
          }
          else if (i == 'loop') {
            config['loop'] = value == 'true';
          }
          else if (i == 'transition') {
            config['effect'] = value;
          }
          else if (i == 'transitionduration') {
            config['speed'] = parseInt(value);
          }
          else if (i == 'autoplayduration') {
            if (value != '0') {
              config['autoplay'] = { delay: value };
            }
          }
          else {
            config[i] = value;
          }
        }

        var mySwiper = new Swiper (element, config)
      });

    });

  }

});
