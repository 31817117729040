import Store from 'utils/store';

window.Video = function() {
  return {
    initialized: false,

    init(type) {

      if (type == 'file') {
        this.initPlayer();
      }
      else {
        if (this.$store.privacy.settings[type]) {
          this.initPlayer();
        }

        Store.watch('privacy.settings.' + type, (value) => {
          this.initPlayer();
        })
      }

    },

    initPlayer() {

      var element = this.$el;

      if (!this.initialized) {
        Promise.all([
          import(
            /* webpackChunkName: "plyr" */
            'plyr/dist/plyr.polyfilled.js'
          ),
          import(
            /* webpackChunkName: "plyr" */
            'plyr/dist/plyr.css'
          ),
          import(
            /* webpackChunkName: "video" */
            './video.css'
          ),
        ]).then(modules => {
          const Plyr = modules[0].default;
          const player = new Plyr(element);
          this.initialized = true;
        });
      }

    },

  }
}
