import ready from "utils/ready";
import { $, $$ } from "utils/selector";

class Aos {
  constructor(options = {}) {
    this.options = Object.assign(
      this,
      {
        root: null, // relative to document viewport
        rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 0.4 // visible amount of item shown in relation to root (higher values can cause problems)
      },
      options
    );

    this.observer = new IntersectionObserver(this.onChange, this.options);
  }

  /**
   * Observe all elements that contain aos attributes
   */
  observeAll() {
    let images = $$("[aos-out]");
    images.forEach(img => {
      img.classList.add('transition');
      this.observer.observe(img)
    });
  }

  /**
   * Unobserve all elements that contain
   * Useful for optimisation / page transitions with swup / turbolinks
   */
  unobserveAll() {
    let images = $$("[aos-out]");
    images.forEach(img => this.observer.unobserve(img));
  }

  /**
   * The intersection observer will fire this function
   * when at least one of our elements intersects / leaves the viewport
   */
  onChange(changes, observer) {
    changes.forEach(change => {
      let target = change.target;
      // let hasIn = target.getAttribute("aos-in");
      let outClasses = target.getAttribute("aos-out");
      if (change.isIntersecting) {
        /*
         * 'split' turns our data attribute string into an array, and the spread operator (three dots)
         * deconstructs it into the format that we need for manipulating the element's 'classList'
         */
        if (outClasses) {
          target.classList.remove(
            ...outClasses.split(" ")
          );
        }

        // if (hasIn) {
        //   target.classList.add(
        //     ...target.getAttribute("aos-in").split(" ")
        //   );
        // }
      } else {
        // if (hasIn) {
        //   target.classList.remove(
        //     ...target.getAttribute("aos-in").split(" ")
        //   );
        // }
        if (outClasses) {
          target.classList.add(
            ...outClasses.split(" ")
          );
        }
      }
    });
  }
}

ready().then(() => {

  (new Aos()).observeAll();

});
