import { $, $$ } from "utils/selector.js";
import ready from 'utils/ready';
import { on } from "utils/events";

window.Map = function() {
  return {
    initialized: false,

    init() {
      if (this.$store.privacy.settings.osm) {
        this.show();
      }

      Spruce.watch('privacy.settings.osm', (value) => {
        this.show();
      });
    },

    show() {
      var element = this.$el;

      if (!this.initialized) {
        Promise.all([
          import(
            /* webpackChunkName: "leaflet" */
            'leaflet/dist/leaflet.js'
          ),
          import(
            /* webpackChunkName: "leaflet" */
            'leaflet-extra-markers/dist/js/leaflet.extra-markers.js'
          ),
          import(
            /* webpackChunkName: "leaflet" */
            'leaflet/dist/leaflet.css'
          ),
          import(
            /* webpackChunkName: "leaflet" */
            'leaflet-extra-markers/dist/css/leaflet.extra-markers.min.css'
          ),
        ]).then(modules => {
          const L = modules[0].default;

          var markers = $$('.marker', element);
          var markerGroup = new L.featureGroup();
          var map = L.map(element, {
            scrollWheelZoom: false
          }); // .setView([47.5718565,13.9978226], 7)

          var OpenStreetMap_DE = L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
            maxZoom: 18,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          }).addTo(map);

          markers.forEach((marker) => {

            var iconSVG = $('.icon', marker);
            var iconHTML = "";

            if (iconSVG) {
              iconHTML = "<div class='flex items-center h-8 justify-center text-white'>" + iconSVG.innerHTML + "</div>";
              iconSVG.remove();
            }

            var redMarker = L.ExtraMarkers.icon({
              innerHTML: iconHTML,
              markerColor: 'blue',
            });
            var m = L.marker([marker.dataset.lat,marker.dataset.lon], {icon: redMarker});

            m.bindPopup(marker.innerHTML);
            m.addTo(map);
            m.addTo(markerGroup);
          });
          $('.js-map__markers', element).remove();

          map.fitBounds(markerGroup.getBounds(), {maxZoom: 17});

          this.initialized = true;
        });

      }

    },
  }
}
