import Cookies from 'js-cookie';
import Store from 'utils/store';
import './dialog/dialog';

window.privacy = Store.store('privacy', function () {
  let data = {
    cookieName: 'privacy',
    showDialog: true,
    showSettingToggles: false,
    settings: {},

    openDialog: function () {
      this.showDialog = true;
      this.showSettingToggles = true;
    },

    acceptService: function(id) {
      this.settings[id] = true;
    },

    isServiceAllowed: function (id) {
      return this.settings[id] == undefined ? false: this.settings[id];
    },

    saveAll: function (val) {
      for (const key in this.settings) {
        if (this.settings.hasOwnProperty(key) && key != '__watchers') {
          this.saveSetting(key, val == undefined ? this.settings[key] : val);
        }
      }
    },
    saveSetting: function(id, val) {
      Cookies.set(this.cookieName + '_' + id, val, { expires: 730 });
    },
    loadSetting: function(id) {
      let cookie = Cookies.get(this.cookieName + '_' + id);

      // show dialog if setting gets loaded which is not currently selected by the user
      if (cookie == undefined) {
        this.showDialog = true;
      }

      return cookie == 'true';
    },

  };

  let cookie = Cookies.get(data.cookieName + '_' + 'dialog_show');
  data.showDialog = (cookie == undefined) ? true : cookie == 'true';

  // Get privacy setting keys to build privacy store
  let privacysettings = window.sitePrivacySettings;

  for (const key in privacysettings) {
    if (privacysettings.hasOwnProperty(key)) {
      data.settings[key] = data.loadSetting(key);
    }
  }

  return data;
});

for (const key in privacy.settings) {
  if (privacy.settings.hasOwnProperty(key)) {
    Store.watch('privacy.settings.' + key, function(value) {
      let name = key;
      Store.store('privacy').saveSetting(name, value);
    });
  }
}

// Store.watch('privacy.showDialog', function(value) {
//   Store.store('privacy').saveSetting('dialog_show', value);
// });
