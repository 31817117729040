import loadScripts from 'utils/load-scripts';
import Store from 'utils/store';
import isLocalUrl from 'utils/is-local-url';

if (privacy.settings.ga && !isLocalUrl()) {
  loadScripts([
    "https://www.googletagmanager.com/gtag/js?id=" + ga_id
  ],function(){
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', ga_id, { 'anonymize_ip': true });
  });
}
